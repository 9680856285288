<template>
  <div>
  <el-popover
    placement="right-start"
    width="180"
    trigger="hover">
    <div class="right-panel">
      <ul class="operate-list">
        <el-popover
          popper-class="special-popover"
          placement="right-start"
          width="180"
          :disabled="disabled || treeData.hasArticle === 1"
          trigger="hover">
          <div class="right-panel" v-loading="loading">
            <ul class="operate-list">
              <li class="operate-item" @click="addFolderBtn">
                <span class="icon">
                  <svg-icon icon-class="folder_on"></svg-icon>
                </span>
                <span class="operate">新建文件夹</span>
              </li>
              <li class="operate-item" @click="addArticleBtn">
                <span class="icon">
                  <svg-icon icon-class="file"></svg-icon>
                </span>
                <span class="operate">新建文档</span>
              </li>
              <li class="operate-item" @click="addNewFileClick('newExcel')">
                <span class="icon">
                  <svg-icon icon-class="excel_icon"></svg-icon>
                </span>
                <span class="operate">新建excle</span>
              </li>
              <li class="operate-item" @click="addNewFileClick('newDoc')">
                <span class="icon">
                  <svg-icon icon-class="word"></svg-icon>
                </span>
                <span class="operate">新建word</span>
              </li>
              <li class="operate-item" @click="addNewFileClick('newPowerPoint')">
                <span class="icon">
                  <svg-icon icon-class="ppt"></svg-icon>
                </span>
                <span class="operate">新建PowerPoint</span>
              </li>
            </ul>
          </div>
          <span slot="reference">
            <li class="operate-item" :class="{'disabled': disabled || treeData.hasArticle === 1 }">
              <span class="icon"></span>
              <span class="operate">新建</span>
              <span class="icon rightIcon">
                <svg-icon icon-class="arrow-right"></svg-icon>
              </span>
            </li>
          </span>
        </el-popover>
        <li class="operate-item" :class="{'disabled': disabled || treeData.hasArticle === 1}">
          <!-- <span class="icon"></span>
          <span class="operate">上传文件</span> -->
          <el-upload
            class="upload-demo upload_button"
            action="http://localhost:8443/content/uploadFile"
            :on-change="handleFileChange"
            :auto-upload="false"
            :limit="1"
            :file-list="fileList"
            :show-file-list="false"
            :disabled="disabled || treeData.hasArticle === 1"
            multiple
            ref="upload">
            <div slot="trigger" style="display: flex;">
              <span class="icon"></span>
              <span class="operate">上传文件</span>
            </div>
          </el-upload>
        </li>
        <el-divider></el-divider>
        <li class="operate-item" @click="shareClick">
          <span class="icon">
            <svg-icon icon-class="share"></svg-icon>
          </span>
          <span class="operate">分享</span>
        </li>
        <li class="operate-item" @click="fuzhi()">
          <span class="icon">
            <svg-icon icon-class="links"></svg-icon>
          </span>
          <span class="operate" v-clipboard:copy="encryptionStr">复制链接</span>
          <!-- 复制成功提示 -->
          <div class="message-tip" v-if="copy">
            <el-icon class="el-icon-check"></el-icon>
          </div>
        </li>
        <el-divider></el-divider>
        <li class="operate-item" :class="{'disabled': !treeData.type}" @click="dowloadClick">
          <span class="icon">
            <svg-icon icon-class="download"></svg-icon>
          </span>
          <span class="operate">下载</span>
        </li>
        <li class="operate-item" @click="renameClick" :class="{'disabled': disabled}">
          <span class="icon"></span>
          <span class="operate">重命名</span>
        </li>
        <li class="operate-item" @click="collectClick">
          <span class="icon">
            <svg-icon icon-class="collect"></svg-icon>
          </span>
          <span class="operate">{{ treeData.isCollect ? '取消收藏' : '添加到收藏'}}</span>
        </li>
        <el-divider></el-divider>
        <li class="operate-item" :class="{'disabled': disabled}" @click="deleteItem">
          <span class="icon">
            <svg-icon icon-class="recycle"></svg-icon>
          </span>
          <span class="operate">删除</span>
        </li>
      </ul>
    </div>
    <span slot="reference" @click.stop="">
      <svg-icon icon-class="ellipsis"></svg-icon>
    </span>
  </el-popover>
  <!-- 添加文件夹 添加文件 弹窗 -->
    <folder-add
      :visible="visible"
      :file_type="file_type"
      :encryptionStr="treeData.encryptionStr"
      @closeTab="closeTab"
      @addFolderSuccess="addFolderSuccess">
    </folder-add>
    <!-- 上传文件 弹窗 -->
    <!-- <upload-file-dialog
      :visible="uploadVisible"
      :encryptionStr="treeData.encryptionStr"
      @closeDialog="closeDialog"
      @uploadSuccess="uploadSuccess">
    </upload-file-dialog> -->
    <!-- 重命名 弹窗 -->
    <re-name-dialog
      :visible="renameVisible"
      :id="treeData.id"
      :name="treeData.name"
      @closeTab="closeReNameTab"
      @renameSuccess="renameSuccess">
    </re-name-dialog>
    <!-- 分享 弹窗 -->
    <share-dialog
      :dialogVisible="shareVisible"
      :chapterObj="chapterObj"
      @closeShareDialog="closeShareDialog"
      @powerSuccess="powerSuccess">
    </share-dialog>
  </div>
</template>
<script>
import folderAdd from '@/components/addFolderDialog/index'
import { downloadFile } from '@/api/fileApi'
import { softDeleteSpace, addContentSpaceNew } from '@/api/space'
// import uploadFileDialog from '@/components/uploadFileDialog/index'
import reNameDialog from '@/components/reNameDialog/index'
import shareDialog from '@/components/shareDialog/index'
import { collectSpace } from '@/api/collect'
import {validateFile} from '@/utils/index'

export default {
  components: {
    folderAdd,
    // uploadFileDialog,
    reNameDialog,
    shareDialog
  },
  data() {
    return {
      copy: false,
      visible: false,
      file_type: null,
      loading: false,
      uploadVisible: false,
      renameVisible: false,
      shareVisible: false,
      chapterObj: {
        chapterId: null,
        encryptionStr: '',
        linkToAll: 1,
        linkWrite: 0
      },
      fileList: []
    }
  },
  props: {
    treeData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'collect'
    }
  },
  computed: {
    encryptionStr() {
      let router = window.location.origin
      // dzz的文档
      if (this.treeData.dpath) {
        router += `/wiki/transfer/${this.treeData.encryptionStr}`
      } else {
        router += this.treeData.hasArticle === 1 ? `/wiki/article/${this.treeData.encryptionStr}` : `/drive/share/folder/${this.treeData.encryptionStr}`
      }
      return router
    },
    disabled() {
      let flag = false
      // return this.treeData.workcode !== JSON.parse(sessionStorage.getItem('urlInfo')).username || this.treeData.permission === 1
      if (this.treeData.workcode !== JSON.parse(sessionStorage.getItem('urlInfo')).username) {
        if (this.treeData.permission === 1) {
          flag = false
        } else {
          flag = true
        }
      }
      return flag
    }
  },
  methods: {
    fuzhi() {
      this.copy = true
      setTimeout(() => {
        this.copy = false
      }, 1500)
    },
    addFolderBtn() {
      if (this.type !== 'my') {
        return
      }
      this.visible = true
    },
    closeTab() {
      this.file_type = null
      this.visible = false
    },
    addFolderSuccess() {
      this.visible = false
      this.file_type = null
      this.$emit('addFolderSuccess')
    },
    addArticleBtn() {
      if (this.type !== 'my') {
        return
      }
      // 先往表中插入数据
      console.log(this.treeData, ';;;')
      const params = {
        parentId: this.treeData.id
      }
      addContentSpaceNew(params).then(res => {
        if (res.code === '000000' && res.data) {
          console.log('res', res.data)
          const { href } = this.$router.resolve({ path: '/wiki/article/' + res.data })
          window.open(href, '_blank')
        }
      }).catch(() => {
        // 创建节点失败，给出提示
      })
    },
    deleteItem() {
      if (!this.disabled) {
        this.$confirm('是否要删除节点或文章?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            softDeleteSpace({ idList: [this.treeData.id] }).then(response => {
              if (response.code === '000000') {
                this.$message.success(response.message)
                this.$emit('deleteSuccess')
              } else {
                this.$message.error(response.message)
              }
            }).catch(err => {
              console.log(err)
            })
          })
          .catch((err) => {
            console.log(err)
            this.$message({
              type: 'info',
              message: '已取消删除操作'
            })
          })
      }
    },
    dowloadClick() {
      if (this.treeData.type) {
        downloadFile({
          mod: 'io',
          op: 'download',
          path: this.treeData.dpath
        }).then((res) => {
          const blob = new Blob([res.data])
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = this.treeData.name
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, this.treeData.name)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    addNewFileClick(file_type) {
      this.visible = true
      this.file_type = file_type
    },
    handleFileChange(file) {
      if (!validateFile(file)) {
        return
      }
      file.encryptionStr = this.treeData.encryptionStr
      file.uplaodTime = new Date()
      // this.$store.dispatch('updateUploadList', file)
      const oldFileList = this.$store.getters.uploadFileList
      oldFileList.push(Object.assign({}, file, {
        upLoadProgress: 0,
        isError: false
      }))
      this.$store.dispatch('updateUploadList', oldFileList)
    },
    // 上传文件
    // uploadFileClick() {
    //   if (!(this.disabled || this.treeData.hasArticle === 1)) {
    //     this.uploadVisible = true
    //   }
    // },
    closeDialog() {
      this.uploadVisible = false
    },
    uploadSuccess() {
      this.uploadVisible = false
      this.$emit('addFolderSuccess')
    },
    renameClick() {
      if (!this.disabled) {
        this.renameVisible = true
      }
    },
    closeReNameTab() {
      this.renameVisible = false
    },
    renameSuccess() {
      this.renameVisible = false
      this.$emit('addFolderSuccess')
    },
    collectClick() {
      collectSpace({
        chapterId: this.treeData.id,
        username: this.$store.getters.name
      }).then(response => {
        if (response.code === '000000') {
          this.$message.success(response.message)
          this.$emit('deleteSuccess')
        } else {
          this.$message.error(response.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    shareClick() {
      this.shareVisible = true
      this.chapterObj = Object.assign(this.chapterObj, this.treeData)
    },
    closeShareDialog() {
      this.shareVisible = false
    },
    powerSuccess() {
      this.$emit('addFolderSuccess')
    }
  }
}
</script>
<style lang="scss" scoped>
.right-panel {
  .operate-item {
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    position: relative;
    &:hover {
      background-color: #ececee;
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 40px;
      margin-right: 5px;
      .svg-icon {
        width: 1.2em;
        height: 1.2em;
      }
      &.rightIcon {
        margin-left: auto;
      }
    }
    .operate {
      display: flex;
      align-items: center;
    }
  }
}
.el-divider--horizontal {
  margin: 5px 0;
}
.disabled {
  color: #bbb;
  cursor: not-allowed !important;
}
.message-tip {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 99;
  .el-icon-check {
    color: #409eff;
  }
}
</style>
