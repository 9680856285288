<template>
  <div class="app-container">
    <table-view></table-view>
  </div>
</template>
<script>
import tableView from './components/tableView'
export default {
  components: { tableView },
  data() {
    return {
      title: '与我协作'
    }
  }
}
</script>
